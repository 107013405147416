import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LET ME <span className="purple"> INTRODUCE </span> MYSELF
            </h1>
            <p className="home-about-body">
              {" "}
              <p>
                I am <span className="purple">Chinmay Patil,</span> also known
                as <span className="purple">4li3n.eth,</span> a computer science
                student with a focus on front-end development, blockchain
                innovations, and cyber security.
              </p>
              <p>
                I have{" "}
                <span className="purple">
                  won multiple hackathons, participated in CTF competitions,
                </span>{" "}
                and solved over 50 challenges on{" "}
                <span className="purple">Hack The Box and TryHackMe.</span>{" "}
              </p>
              <p>
                {" "}
                Notably, I reached the{" "}
                <span className="purple">
                  Grand Finale of the Smart India Hackathon 2023
                </span>{" "}
                and wrote a{" "}
                <span className="purple">
                  Cyber Security "Cheat Sheet" (Hackbook)
                </span>{" "}
                for exams, certifications, and competitions
              </p>
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
