import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import crypto from "../../Assets/Projects/cryptod5.png";
import base from "../../Assets/Projects/base.png";
import ctf from "../../Assets/Projects/ctf.png";
import ssi from "../../Assets/Projects/ssi.png";
import defi from "../../Assets/Projects/defi.png";
import hackbook from "../../Assets/Projects/hackbook.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={ssi}
              isBlog={false}
              title="Self-Sovereign Identity"
              description="Self-Sovereign Identity (SSI) is a concept in which individuals or organizations have sole ownership of their digital and analog identities.
               Using blockchain technology, we provide a secure and efficient way to manage and verify your identity."
              ghLink="https://github.com/0xali3n/SSI"
              demoLink="https://ssi.chinmay.io/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={hackbook}
              isBlog={false}
              title="My Hack Book"
              description="A comprehensive cheat sheet by Chinmay Patil (4li3n.eth) designed for exams, certifications, and competitions. Includes all necessary codes, commands, and techniques in an easy-to-understand language."
              ghLink="https://github.com/0xali3n/Hack-Book"
              demoLink="https://notes.chinmay.io/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={ctf}
              isBlog={false}
              title="Capture The Flag Walkthrough"
              description="Detailed walkthroughs for Hack The Box and TryHackMe machines.
Step-by-Step Instructions: Easy-to-follow steps for solving various CTF challenges.
Practical Commands and Tools: All necessary commands and tools are provided and explained"
              ghLink="https://github.com/0xali3n/CTF-s"
              demoLink="https://ctf.chinmay.io/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={crypto}
              isBlog={false}
              title="CryptoD5.com"
              description="This project addresses the need to monitor and track illegal activities performed using cryptocurrency transaction technology. The goal is to develop tools and techniques to de-anonymize activities on the dark web and in cryptocurrency transactions."
              //ghLink="https://github.com/soumyajit4419/Plant_AI"
              demoLink="#"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={defi}
              isBlog={false}
              title="DeFiChain.in"
              description="Embark on a DeFiChain Journey: Explore, Earn, and Influence. Dive into DeFiLearn Quests for Knowledge, Tokens, and Governance Power."
              ghLink="https://devfolio.co/projects/defichainin-learn-empower-and-earn-dollardfi-2ca8"
              demoLink="https://defichain.in/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={base}
              isBlog={false}
              title="Base Quest Onchain Summer"
              description="Introducing the Base OnChain Summer Event Participation Tracker – your personal portal to seamlessly monitor and relish your journey through the vibrant OnChain Summer event."
              ghLink="https://devfolio.co/projects/basequest-or-track-your-activity-ec94"
              demoLink="https://base-quest.chinmay.io/"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
