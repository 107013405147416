import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  SiVisualstudiocode,
  SiPostman,
  SiSlack,
  SiVercel,
  SiMacos,
} from "react-icons/si";

function Toolstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <p className="home-about-body">
        <ul>
          <li>
            <strong className="purple">Web Development :</strong> VSCode,
            Hostinger Cloud Hosting (for deployment)
          </li>
          <br />
          <li>
            <strong className="purple">Blockchain :</strong> Remix IDE, Ethereum
            Testnet
          </li>
          <br />
          <li>
            <strong className="purple">Cyber Security :</strong> Nmap, Burp
            Suite, Metasploit, John the Ripper, Hydra, Kali Linux
          </li>
          <br />
        </ul>
      </p>
    </Row>
  );
}

export default Toolstack;
