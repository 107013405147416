import React from "react";
import { Col, Row } from "react-bootstrap";
import { CgCPlusPlus } from "react-icons/cg";
import {
  DiJavascript1,
  DiReact,
  DiNodejs,
  DiMongodb,
  DiPython,
  DiGit,
  DiJava,
} from "react-icons/di";
import {
  SiRedis,
  SiFirebase,
  SiNextdotjs,
  SiSolidity,
  SiPostgresql,
} from "react-icons/si";
import { TbBrandGolang } from "react-icons/tb";

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <p className="home-about-body">
        <ul>
          <li>
            <strong className="purple">Languages :</strong> C, C++, Python
          </li>
          <br />
          <li>
            <strong className="purple">Web Development :</strong> React, HTML,
            CSS, JavaScript, TypeScript
          </li>
          <br />
          <li>
            <strong className="purple">Blockchain :</strong> Solidity
          </li>
          <br />
          <li>
            <strong className="purple">Cyber Security :</strong> Penetration
            testing, Vulnerability Scanning
          </li>
          <br />
        </ul>
      </p>
    </Row>
  );
}

export default Techstack;
